import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Form, Button, Row, Col, Alert } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLevelUpAlt } from "@fortawesome/pro-solid-svg-icons"


export default function RaffleTicketsForm() {
    
    const {register, formState: { errors, isValid, isSubmitted }, reset, watch} = useForm({
        mode: "onChange"
    });
    const [succeeded, setSucceeded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState('');
    const watchObject = watch();
    const [disableSubmit, setDisableSubmit] = useState(true)

    console.log(watchObject)

    const handleSubmit = async ev => {

    // Gatsby check to make sure document is defined
    let data = null
        if (typeof document !== undefined) {
            data = document.forms["contact-form"]
          }
          ev.preventDefault();
          setProcessing(true);
          setDisableSubmit(true);
          
          //alert(JSON.stringify(watchObject));
          //setSucceeded(true);
        //console.log(data);
        fetch('https://lkpsj06pw4.execute-api.us-west-2.amazonaws.com/production/uss-idaho-raffle-tickets', {
          method: 'POST',
          headers: {
            "Content-Type": "application/json"
        },            
            body: JSON.stringify(watchObject)
    })
    .then((response) => {
        if (response.status === 200) {
            console.log(JSON.stringify(watchObject));
            //setMessage('Message Sent!');
            setError(null);
            setProcessing(false);
            setSucceeded(true);
            reset();
            setDisableSubmit(true);
        } else {
            console.log('network error')
        }
    })
    .then(
      fetch(
          `https://script.google.com/macros/s/AKfycbwKNs8KX7r55KP1kAk-ITYUblrIaw8vZaN57p0-I15dOt0N4ns2A57is_5w19HvTGGewA/exec`,
          {
              method: "POST",
              body: new FormData(data),
          }
      )
      .then((response) => {
          if (response.status === 200) {
            console.log('Data submitted to Google Sheet') 
          } else {
              console.log('Error submiting to Google Sheet')
          }
      })
      .catch((error) => console.log('Google error!', error.message))
  )       
    setTimeout((data) => { 
    }, 4000)
    
  }

  const SubmitSuccessMessage = () => (
    <div className="submit-success-message text-center">
      <Alert variant="success">
        <h3>Thank you for your submission.</h3>
        <p>A representative will be in touch shortly.</p>
        </Alert>
    </div>
)
    return (
        <div>
            
          { succeeded ? <SubmitSuccessMessage /> : (
              <>
              <h3>Complete the form below to reserve your raffle tickets. A committee member will then contact you to secure payment for your tickets.</h3>
            <Form onSubmit={handleSubmit}
                id="contact-form"
                name="contact-form"
            >
              <input type="hidden" name="form_type" id="form_type" {...register('form_type')} value="Raffle Ticket Reservation Form" />
              <Row>
                  <Col sm={12} md={6}>
                  <Form.Group>
               <Form.Control
            className="formField"
            type="text"
            name="Name"
            placeholder="Name (required)"
            {...register("Name", { required: true })}
          />
           {" "}{errors.Name && <span className="formError">Cannot be blank <FontAwesomeIcon
                    icon={faLevelUpAlt}
                    style={{
                        fontSize: '14px',
                    }}
                    aria-hidden="true"
                /></span> }
        </Form.Group>
                  </Col>
                  <Col sm={12} md={6}>
                  <Form.Group>
          <Form.Control
          className="formField"
            type="email"
            name="Email"
            placeholder="Email (required)"
            {...register("Email", { required: "Enter your e-mail",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: "Enter a valid e-mail address",
            }, })}
          />
          {" "}{errors.Email && <span className="formError">{errors.Email.message} <FontAwesomeIcon
                    icon={faLevelUpAlt}
                    style={{
                        fontSize: '14px',
                    }}
                    aria-hidden="true"
                /></span> }
        </Form.Group>
                  </Col>
              </Row>
            
        
        <Form.Group>
          <Row>
          <Col sm={12} md={6}>
          <Form.Control
          className="formField"
            type="text"
            name="Phone"
            required
            placeholder="Phone (mobile preferred)"
            {...register("Phone",{ required: true, pattern: {
                  value: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
                  message: "Please enter a valid phone number"
                }
            }
            )}
          />
          {" "}{errors.Phone && <span className="formError">{errors.Phone.message} <FontAwesomeIcon
                    icon={faLevelUpAlt}
                    style={{
                        fontSize: '14px',
                    }}
                    aria-hidden="true"
                /></span> }
          </Col>
          <Col sm={12} md={6}>
            <Form.Control 
                type="number"
                name="Tickets"
                required
                placeholder="Number of Raffle Tickets"
                min="1"
                step="1"
                {...register("Tickets",{required: true})}
            />

          </Col>
          </Row>
        </Form.Group>           
          <Form.Row>
            <>

              <Col>
              <Form.Row>
                <Col>
                <Form.Group>
               <Form.Control
            className="formField"
            type="text"
            name="address"
            placeholder="Mailing Address"
            {...register("Mailing Address", { required: true })}
          />
           {" "}{errors.address && <span className="formError">Cannot be blank <FontAwesomeIcon
                    icon={faLevelUpAlt}
                    style={{
                        fontSize: '14px',
                    }}
                    aria-hidden="true"
                /></span> }
        </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col sm={4}>
                <Form.Group>
               <Form.Control
            className="formField"
            type="text"
            name="addressCity"
            placeholder="City"
            {...register("addressCity")}
          />
           {" "}{errors.addressCity && <span className="formError">Cannot be blank <FontAwesomeIcon
                    icon={faLevelUpAlt}
                    style={{
                        fontSize: '14px',
                    }}
                    aria-hidden="true"
                /></span> }
        </Form.Group>
                </Col>
                <Col sm={4}>
                <Form.Group>
               <Form.Control
            className="formField"
            type="text"
            name="addressState"
            placeholder="State"
            {...register("addressState")}
            
          />
           {" "}{errors.addressState && <span className="formError">Cannot be blank <FontAwesomeIcon
                    icon={faLevelUpAlt}
                    style={{
                        fontSize: '14px',
                    }}
                    aria-hidden="true"
                /></span> }
        </Form.Group>
                </Col>
                <Col sm={4}>
                <Form.Group>
               <Form.Control
            className="formField"
            type="text"
            name="addressZip"
            placeholder="Zip"
            
            {...register("addressZip")}
          />
           {" "}{errors.addressZip && <span className="formError">Cannot be blank <FontAwesomeIcon
                    icon={faLevelUpAlt}
                    style={{
                        fontSize: '14px',
                    }}
                    aria-hidden="true"
                /></span> }
        </Form.Group>
                </Col>
              </Form.Row>
              </Col>
              </>
              
          </Form.Row>

        <Form.Row className="my-4">
        
        <Col sm={12}>
        <Form.Group controlId="formBasicCheckbox">
    <Form.Check type="checkbox" label="Sign up for our newsletter?" name="Newsletter"
      className="checkbox"
              id="newsletter_yes"
              value="Yes"
              {...register("Newsletter")}
              />
  </Form.Group>
            </Col>            
        </Form.Row>   
            
              <Button type="submit" variant="primary" form="contact-form" disabled={!isValid} className="submitButton teamButton">
            {isSubmitted
                ? "Message submitted"
                : processing
                ? "Submitting..."
                : "Submit"}
            </Button>
            
            
        
            </Form>
            </> )
}
</div>
        
    )
}
