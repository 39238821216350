import React from "react"
import SEO from '../components/seo'
import Layout from '../components/layout'
import ContentBlock from '../components/ContentBlock/ContentBlock'
import PageTitle from '../components/PageTitle/PageTitle'
import { Container } from 'react-bootstrap'
import RaffleTicketsForm from "../components/RaffleTicketsForm/RaffleTicketsForm"

const Events = ({ data }) => {

  return (

<Layout>
<SEO title="USS IDAHO Raffle Tickets"/>
<Container fluid className="container-fluid-full">
  <PageTitle pageTitle="USS IDAHO Raffle Tickets" >
    {/* {page.pageTitle} */}
  </PageTitle>
  <ContentBlock>
    <RaffleTicketsForm />
      
    </ContentBlock>
    </Container>
</Layout>
  )
}


export default Events
