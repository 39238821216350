import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import * as styles from './contentBlock.module.css'

const ContentBlock = ({ children }) => (
    <div className={styles.raisedContainer}>
        <Container fluid>
            <Row className="p-4">
                <Col>
                { children }
                </Col>
            </Row>
        </Container>
    </div>
)

export default ContentBlock;