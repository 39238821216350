import React from 'react'
import { Link } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'

import * as styles from './pageTitle.module.css'

const PageTitle = ( props ) => (
    <div className={styles.titleContainer}>
        <Container fluid>
            <Row className="p-4 mb-4 h-100 align-items-center">
                <Col>
                <h1 className={styles.title}>{ props.pageTitle}</h1>
                </Col>
                {/* <Col sm={4} className="text-right h-100 align-items-center">
                <p className={styles.breadcrumbs}><Link to={props.link}>Home</Link> / {props.subPage} / {props.pageTitle}</p>
                </Col> */}
            </Row>
        </Container>
    </div>
)

export default PageTitle;